import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import AFTableColumn from "af-table-column"
import 'element-ui/lib/theme-chalk/index.css';
import jsCookie from 'js-cookie';
import axios from "axios"
import com from '@/components';
import store from './store';
import { tokenOrToken } from "./api/bisystem/bisystem"
import { setSession } from '@/utils/methods';

import '@/utils/flexible.js'
/* vant组件引用 */
import { Form, Field, Button, Calendar, ActionSheet, DatetimePicker, Picker, Popup, Divider, Collapse, CollapseItem, Checkbox, CheckboxGroup, Cell, CellGroup  } from 'vant';
/* vant组件--------------- */
// let requestUrl = "http://4e3940m573.zicp.vip:53710"
// 4e3940m573.zicp.vip:40668/
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.use(com);
Vue.use(AFTableColumn);
/* vant组件引用 */
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Calendar);
Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Divider);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Cell);
Vue.use(CellGroup);
/* vant组件--------------- */
Vue.config.productionTip = false
// Vue.prototype.$url = requestUrl
// let routerlisr = {
//     path: "/echarshtlm3",
//     name: 'echarshtlm',
//     meta: {
//       metadisabled: true,
//       title: '首页'
//     },
//     component: ""
// }
// router.options.routes[0].children.push(routerlisr)
var pcEquipment;
var sUserAgent = navigator.userAgent.toLowerCase();
　　var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
　　var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
　　var bIsMidp = sUserAgent.match(/midp/i) == "midp";
　　var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
　　var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
　　var bIsAndroid = sUserAgent.match(/android/i) == "android";
　　var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
　　var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
　　if ((bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) ){
　　　　pcEquipment = false
　　} else {
      pcEquipment = true
　　}
Vue.prototype.$pcEquipment = pcEquipment

router.beforeEach((to, from, next) => {
  if(to.meta.title) {
		document.title = to.meta.title;
	}
  console.log('to', to)
  if(to.name != 'Login') {
    if(jsCookie.get("token")) {
      //next
	    setSession('tagStep', from.fullPath);

      next()
    }else {
      if (window.name) {
        let dataeds = {
          sysCode: "bs",
          token: window.name
        }
        tokenOrToken(dataeds).then(res => {
          jsCookie.set("token", res.token);
          next()
        })
      } else {
        next("/login")
      }
    }
  }else {
    if(jsCookie.get("token")) {
      next("/echarshtlm")
    }
    next()

  }
})


new Vue({
  el: '#app',
  router,
  axios,
  store,
  render: h => h(App),
}).$mount('#app')
