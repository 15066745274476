<template>
  <div class="dateInput">
    <van-field readonly clickable name="开始时间" v-model="time.startDate" label="开始时间" placeholder="点击选择日期" @click="timeClick" ></van-field>
    <van-field readonly clickable name="结束时间" v-model="time.endDate" label="结束时间" placeholder="点击选择日期" @click="timeClick" ></van-field>
    <van-calendar type="range" v-model="showCalendar" :default-date="defaultDate" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate"></van-calendar>
  </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'Y-dateInput',
    props: {
      inputValue: {
        type: Object,
        default: {},
      },
    },
    watch: {
      inputValue: {
        handler(newName) {
          this.time = newName
        },
        immediate: true,
        deep: true,
      }
    },
    data () {
        return {
          showCalendar: false,
          time: {
            startDate: '',
            endDate: ''
          },
          minDate: new Date(2000, 0, 1),
          maxDate: new Date(2022, 0, 31),
          defaultDate: []
        }
    },
    created () {
      this.time = this.inputValue
    },
    methods: {
      timeClick () {
        this.defaultDate = []
        this.defaultDate.push(new Date(this.time.startDate))
        this.defaultDate.push(new Date(this.time.endDate))
        this.showCalendar = true
      },
      onConfirm (date) {
        this.time.startDate = moment(date[0]).format('YYYY-MM-DD')
        this.time.endDate = moment(date[1]).format('YYYY-MM-DD')
        this.showCalendar = false
        this.$emit('onConfirm', this.time)
      },
    },
    mounted () {
    }
}
</script>
<style scoped lang="less">
</style>
