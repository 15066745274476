import request from "@/axios/index";
// import qs from 'qs'

// 查询大类
export function getLargeCategory(params) {
  return request({
    url: '/cat_config/with_children',
    method: "get",
    params
  })
}
// 新增/修改大类
export function addsetLargeCategory(data) {
    return request({
      url: '/cat_config',
      method: "post",
      data
    })
}
// 删除大类
export function deleteLargeCategory(data) {
    return request({
      url: '/cat_config',
      method: "delete",
      data
    })
}


// 展示获取图表
export function getChartShow(params) {
  return request({
    url: '/charts',
    method: "get",
    params
  })
}

// 图表对应表格


export function getChartTable(params, data) {
  return request({
    url: '/charts/dataset',
    method: "get",
    params,
    data
  })
}

// 图表对应表格
export function getChartTablepost(params, data) {
  return request({
    url: '/charts/dataset',
    method: "post",
    params,
    data
  })
}


// 查找主表上方筛选条件接口
export function getFilter(params) {
  return request({
    url: '/dataset_config/filters',
    method: "get",
    params
  })
}


// 查找合计
export function TableSum(params,data) {
  return request({
    url: '/charts/dataset_cnt',
    params,
    method: 'post',
    data,
  })
}

// 导出图片和表格
export function exportEcharts(data) {
  return request({
    url: '/charts/download',
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    method: 'post',
    data,
    responseType: 'blob'
  })
}


// 查询筛选条件
export function getScreeningCondition(params) {
  return request({
    url: '/charts/filter',
    method: "get",
    params
  })
}
