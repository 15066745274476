import request from "@/axios/index";
// import qs from 'qs'
// 分页查询数据集
export function getdataSetlistPage(params) {
  return request({
    url: '/dataset_config/page',
    method: "get",
    params
  })
}
// 不分页查询数据集
export function getdataSetlist(params) {
  return request({
    url: '/dataset_config',
    method: "get",
    params
  })
}

// 根据数据集查询关联图表
export function getChartData(params) {
  return request({
    url: '/cat_config/v2',
    method: "get",
    params
  })
}


// 加载sql
export function getloadSql(data) {
  return request({
    url: '/dataset_config/load_sql',
    method: "post",
    data
  })
}
// 修改数据集明细
export function setDeiledataSetlist(data) {
  return request({
    url: '/field_prop_config',
    method: "post",
    data
  })
}

// 新增数据集
export function adddataSetlist(data) {
  return request({
    url: '/dataset_config',
    method: "post",
    data
  })
}
// 查询全部数据库链接
export function getdataBaseLinklist(params) {
  return request({
    url: '/db_conn_config',
    method: "get",
    params
  })
}
// 查询数据集明细
export function getdataBaseLinkDetailt(params) {
  return request({
    url: '/dataset_config/detail',
    method: "get",
    params
  })
}
// 删除数据集
export function deletedataSetlist(data) {
  return request({
    url: '/dataset_config',
    method: "delete",
    data
  })
}
// export function loginfunction(data) {
//     return request({
//         url: '/login',
//         method: "post",
//         data
//     })
// }
//展示页面，子表接口
export function detailDeptree(params) {
  return request({
    url: '/dataset_config/detail_deptree',
    method: "get",
    params
  })
}
