<template>
  <div class="selectInout">
    <van-field v-model="inputval.text" :name="inputName" :label="inputName" :placeholder="'点击选择' + inputName" readonly @click="showPickerClick(inputval)"></van-field>
    <van-popup v-model="showPicker" position="bottom" :style="{ height: '50%' }">
      <van-picker show-toolbar :default-index="defaultIndex" :columns="columnsList" @confirm="onConfirmSelect" @cancel="showPickerNO"></van-picker>
    </van-popup>
  </div>
</template>

<script>
export default {
    name: 'Y-selectInout',
    props: {
      inputName: {
        type: String,
        default: "",
      },
      inputValue: {
        type: Object,
        default: {
          text: '',
          value: ''
        },
      },
      columns: {
        type: Array,
        default: [],
      }
    },
    watch: {
      inputValue: {
        handler(newName) {
          this.inputval = newName
        },
        immediate: true,
        deep: true
      },
      columns: {
        handler(newName) {
          this.columnsList = newName
        },
        immediate: true,
        deep: true
      }
    },
    data () {
        return {
          inputval: {},
          showPicker: false,
          columnsList: [],
          defaultIndex: ''
        }
    },
    created () {
    },
    methods: {
      showPickerClick (val) {
        for (let i = 0; i < this.columnsList.length; i ++) {
          if (val.text == this.columnsList[i].text) {
            this.defaultIndex = i
          }
        }
        this.showPicker = true
      },
      onConfirmSelect (val) {
        this.showPicker = false
        this.$emit("onConfirmSelect", val)
      },
      showPickerNO () {
        this.showPicker = false
      },
    },
    mounted () {
    }
}
</script>
<style scoped lang="less">
</style>