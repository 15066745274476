<template>
 <div>
    <div v-if="dblclickObj != null">
        <div style="display:flex;justify-content:space-between;margin-bottom:10px">
          <span>数据源:</span>
          <el-select clearable v-model="dblclickObj.config.dataset.value" placeholder="请选择" size="mini" @change="datasourceChange">
            <el-option
              v-for="item in dataSourceOption"
              :key="item.id"
              :label="item.dsName"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div style="display:flex;justify-content:space-between;margin-bottom:10px">
          <span>分组字段:</span>
          <el-select clearable v-model="dblclickObj.config.key.value" placeholder="请选择" size="mini" @change="abscissaChange">
            <el-option
              v-for="item in abscissaOption"
              :key="item.id"
              :label="item.showName"
              :value="item.fname">
            </el-option>
          </el-select>
        </div>
        <div style="display:flex;justify-content:space-between;margin-bottom:10px">
          <span>统计字段:</span> 
          <el-select
            clearable
            v-model="dblclickObj.config.value.value"
            style="margin-left: 20px;"
            placeholder="请选择"
            size="mini"
            @change="rowGroupChange"
          >
            <el-option
              v-for="item in abscissaOption"
              :key="item.value"
              :label="item.showName"
              :value="item.fname">
            </el-option>
          </el-select>
        </div>
    </div>
 </div>
</template>
<script>
import { getAbscissa } from "@/api/catConfig"
 export default {
   name: 'Y-charbB',
   components: {
   },
   props: {
     dblclickObj: {
       type: Object,
       default: null,
     },
     dataSourceOption: {
       type:Array,
       default: []
     }
   },
   watch:{
     dblclickObj(val) {
       this.datasourceChange(val.config.dataset.value)
     }
   },
   data () {
     return {
       dataSource: '', 
       rowField: '',
       groupByField:'',
       dataSources: [],
       abscissaOption: [], // 横坐标
     }
   },
   created () {
     this.init()
   },
   mounted () {
   },
   methods: { 
     init() {
     },
     rederChange(histogram){
      // const findHistogram = this.histogramList.find((histogram) => histogram.id === this.dblclickIndex);
      // findHistogram.myChart.setOption(findHistogram.option);
      histogram.myChart.setOption(histogram.option);
    },
    // 选择数据源
    datasourceChange(val) {
      // 查横坐标相关接口
      getAbscissa({dsId: val}).then(res => {
          this.abscissaOption = res
      })
      // 赋值
      const item = this.dataSourceOption.filter(item => {
        return item.id == val
      })
      this.dblclickObj.config.dataset.value = item[0].id
      this.dblclickObj.config.dataset.label = item[0].dsName
    },
    // 选择横坐标字段
    abscissaChange(val) {
      const item = this.abscissaOption.filter(item => {
        return item.fname == val
      })
      this.dblclickObj.config.key.value = item[0].fname
      this.dblclickObj.config.key.label = item[0].showName
    },
    // 横坐标分组
    rowGroupChange(val) {
      const item = this.abscissaOption.filter(item => {
        return item.fname == val
      })
      this.dblclickObj.config.value.value = item[0].fname
      this.dblclickObj.config.value.label = item[0].showName
    },
   },
 }
</script>

<style scoped lang='scss'>

 
</style>
