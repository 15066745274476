import Vue from 'vue'
import VueRouter from 'vue-router'

import { setSession, getSession } from '@/utils/methods';
// import { routerdataed } from "@/api/routeraxios/index.js"
import store from "@/store"
import jsCookie from 'js-cookie';
import baseRoutes from '@/router/methodsrouter.js';
Vue.use(VueRouter)

// 消除路由重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}
// let sessiondata = getSession('routersess');
let routes = baseRoutes
// Pm.then(res => {
// 	routes = res
// })
// routerdataed().then(res => {
// })
const router = new VueRouter ({
  routes: routes
})

// const router = new VueRouter({
//   routes
// })

router.beforeEach((to, from, next) => {
	// Vue.prototype.CancelApi.clear();
	// const token = getToken();
	// const token = getSession('vue_admin_template_token');
	const { name, fullPath, hash, meta, params, path, query } = to;
		// if (from.path === '/login') {
		// 	getUserInfo().then(res => {
		// 		store.commit('global/GET_USERINFO', res);
		// 		setSession('userInfo',res);
		// 	});
		// }
		const obj = { name, fullPath, hash, meta, params, path, query };
		if (path != '/login') {
			store.dispatch('global/TAG_ADD', obj);
		}
		next();
		// if (to.path == '/login') {
		// 	next();
		// } else {
		// 	next({ path: '/login', replace: true });
		// }
});


// router.beforeEach((to, from, next) => {
//   if (to.meta.metadisabled) {
//     let name = jsCookie.get('tokenspid')
//     if (name) {
//       next()
//     } else{
//       next({
//         path: '/Login',
//       })
//     }
//   } else {
//     next()
//   }
//   // if (to.meta.requireAuth) {
//   //     //判断该路由是否需要登录权限
//   //     if (cookies('token')) {
//   //         //通过封装好的cookies读取token，如果存在，name接下一步如果不存在，那跳转回登录页
//   //         next()//不要在next里面加"path:/",会陷入死循环
//   //     }
//   //     else {
//   //         next({
//   //             path: '/login',
//   //             query: {redirect: to.fullPath}//将跳转的路由path作为参数，登录成功后跳转到该路由
//   //         })
//   //     }
//   // }
//   // else {
//   //     next()
//   // }
// })

export default router
