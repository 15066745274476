import { render, staticRenderFns } from "./charbB.vue?vue&type=template&id=3eced498&scoped=true&"
import script from "./charbB.vue?vue&type=script&lang=js&"
export * from "./charbB.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eced498",
  null
  
)

export default component.exports