export const setSession = (name = '', data) => {
	if (sessionStorage) {
		let o = JSON.stringify(data);
		sessionStorage.setItem(name, o);
	}
};
export const removeSession = (name = '') => {
	if (sessionStorage) {
		sessionStorage.removeItem(name);
	}
};
export const getSession = name => {
	if(sessionStorage) {
		sessionStorage.getItem(name);
	}
};
export const setLocal = (name = '', data) => {
	if (localStorage) {
		let o = JSON.stringify(data);
		localStorage.setItem(name, o);
	}
};
export const removeLocal = (name = '') => {
	if (localStorage) {
		localStorage.removeItem(name);
	}
};
export const getLocal = name => {
	if(localStorage) {
		let localspan = localStorage.getItem(name);
		return localspan
	}
};