import { routerdataed } from "@/api/routeraxios"
import routerjs from "./routers.js"
import Home from '../views/Home'
import { getLargeCategory } from "@/api/chart/index.js"
import { getSession } from '@/utils/methods';
import jsCookie from 'js-cookie';
import { tokenOrToken } from "../api/bisystem/bisystem"
const childrenlist = [
    // {
    //     path: '/echarshtlm',
    //     name: 'echarshtlm',
    //     // redirect: '/echarshtlm',
    //     // alias: '/echarshtlm',
    //     meta: {
    //       metadisabled: true,
    //       title: '通用图表展示页面'
    //     },
    //     component: routerjs.echarshtlm
    // },
    {
        path: '/databaseLink',
        name: 'databaseLink',
        meta: {
            metadisabled: true,
            title: '数据库链接'
        },
        component: routerjs.databaseLink
    },
    {
        path: '/dataSet',
        name: 'dataSet',
        meta: {
            metadisabled: true,
            title: '数据集'
        },
        component: routerjs.dataSet
    },
    {
        path: '/setDataSet',
        name: 'setDataSet',
        meta: {
            metadisabled: true,
            title: '新增数据集'
        },
        component: routerjs.setDataSet
    },
    {
        path: '/addDataSet/:id',
        name: 'addDataSet',
        meta: {
            metadisabled: true,
            title: '编辑数据集'
        },
        component: routerjs.addDataSet
    },
    {
        path: '/chart',
        name: 'chart',
        meta: {
            metadisabled: true,
            title: '图表设置'
        },
        component: routerjs.chart
    },
    {
        path: '/setTable/:id',
        name: 'setTable',
        meta: {
            metadisabled: true,
            title: '新增图表'
        },
        component: routerjs.setTable
    },
    {
        path: '/addTable/:id/:dataId/:name/:heightList',
        name: 'addTable',
        meta: {
            metadisabled: true,
            title: '编辑图表'
        },
        component: routerjs.addTable
    },
]
childrenlist.push(
    {
        path: '/echarshtlm',
        name: 'echarshtlm',
        meta: {
          metadisabled: true,
          title: '首页'
        },
        component: routerjs.echarshtlm
    },
)
// window.addEventListener('message', event => {
//     let data = event.data;
//     switch (data.iframeClick) {
//       case 'getParams':
//         let myToken = data.params.key; // 这样就拿到了vue传过来的params
//         // jsCookie.set("token", res.token);
//         let dataeds = {
//           sysCode: "bs",
//           token: myToken
//         }
//         if (!jsCookie.get("token")) {
//           tokenOrToken(dataeds).then(res => {
//             jsCookie.set("token", res.token);
//           })
//         }
//       break;
//     }
//   });
if(jsCookie.get("token")) {
    getLargeCategory().then((res) => {
        let data = dun(res)
        duna(data)
        // adds = 
    })
    function dun (data) {
        return data
    }
    function duna (data) {
        let routerdata = []
        for (let i = 0; i < data.length; i ++) {
            if (data[i].children && Array.isArray(data[i].children)) {
                for (let j = 0; j < data[i].children.length; j ++) {
                    routerdata.push(data[i].children[j])
                }
            }
        }
        // for (let k = 0; k < routerdata.length; k ++) {
        //     childrenlist.push(
        //         {
        //             path: '/echarshtlm' + k,
        //             name: 'echarshtlm',
        //             meta: {
        //                 metadisabled: true,
        //                 title: routerdata[k].catName
        //             },
        //             component: routerjs.echarshtlm
        //         }
        //     )
        // }
    }
}
// for (let i = 0; i < childrenlist.length; i ++) {
//     childrenlist[i].component = routerjs.echarshtlm
// }
let baseRoutes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
      redirect: '/echarshtlm',
      meta: {
        metadisabled: true
      },
      children: childrenlist
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('../views/login/index.vue')
    },
    {
        path: '/moveCharts/:id/:name',
        name: 'moveCharts',
        meta: {
            metadisabled: true,
            title: '图表展示'
        },
        component: () => import("@/views/moveCharts/index.vue")
    },
]
// let Pm = new Promise(function (resolve, reject) {
//     let baseRoutes = []
//     setTimeout(() => {
//         baseRoutes = [
//             {
//               path: '/',
//               name: 'Home',
//               component: Home,
//               redirect: 'echarshtlm',
//               meta: {
//                 metadisabled: true
//               },
//               children: childrenlist
//             },
//             {
//               path: '/Login',
//               name: 'Login',
//               component: () => import('../views/login/index.vue')
//             },
//         ]
//         resolve(baseRoutes)
//     }, 1000);
// })
// export { Pm }
export default baseRoutes