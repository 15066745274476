const echarshtlm = () => import("@/views/echarshtlm/index.vue");
const databaseLink = () => import("@/views/databaseLink/index.vue");
const dataSet = () => import("@/views/dataSet/index.vue");
const setDataSet = () => import("@/views/dataSet/setindex.vue");
const addDataSet = () => import("@/views/dataSet/setindex.vue");
const chart = () => import("@/views/chart/index.vue");
// const setTable = () => import("@/views/chart/setindex.vue");
// const addTable = () => import("@/views/chart/setindex.vue");
const setTable = () => import("@/views/chart/setchartlist.vue");
const addTable = () => import("@/views/chart/setchartlist.vue");
const moveCharts = () => import("@/views/moveCharts/index.vue");
export default {
  echarshtlm,
  databaseLink,
  dataSet,
  setDataSet,
  chart,
  setTable,
  addDataSet,
  addTable,
  moveCharts,
};
