<template>
    <div class="home">
        <div v-if="pcEquipment" class="homesun">
          <el-container>
            <el-aside :width="width">
                 <el-col >
                    <el-menu
                      :default-active='datasetId'
                      class="el-menu-vertical-demo"
                      @open="handleOpen"
                      @close="handleClose"
                      background-color="#304156"
                      text-color="#fff"
                      :collapse="collapse"
                      unique-opened
                      :collapse-transition="false" 
                      active-text-color="#409EFF"
                      router
                    > 
                        <div style="width: 100%; height: 60px; overflow: hidden;" :class="logoboxttd">
                          <span v-if="logospandisabled" class="logospan">汇 信 云 商 BI</span>
                        </div>
                        <!-- <el-menu-item index="/echarshtlm" v-show="qihuadisabled">
                          <i class="el-icon-document"></i>
                          <span slot="title">图表展示</span>
                        </el-menu-item> -->
                        

                        <!-- 原来的图表展示 -->
                        <!-- <el-submenu v-for="(item, index) in routerdata" :key="index" :index="index +''" v-show="qihuadisabled">
                          <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>{{ item.catName }}</span>
                          </template>
                          <el-menu-item v-for="(team, index) in item.children" :key="index +''" :index="team.id +''" route="/echarshtlm" @click="clickedsa(team)">{{ team.catName }}</el-menu-item>
                        </el-submenu> -->
                        

                        <!-- 现在路由展示数据集 -->
            
                        <el-menu-item v-show="qihuadisabled" v-for="(team, index) in routerdata" :key="index +''" :index="team.id" route="/echarshtlm" @click="clickedsa(team)">{{ team.dsName }}</el-menu-item>

                        <!-- 图表设计模块 -->
                        <el-menu-item index="/databaseLink" v-show="!qihuadisabled">
                          <i class="el-icon-document"></i>
                          <span slot="title">数据库链接</span>
                        </el-menu-item>
                        <el-menu-item index="/dataSet" v-show="!qihuadisabled">
                          <i class="el-icon-document"></i>
                          <span slot="title">数据集</span>
                        </el-menu-item>
                        <el-menu-item index="/chart" v-show="!qihuadisabled">
                          <i class="el-icon-document"></i>
                          <span slot="title">图表</span>
                        </el-menu-item>

                        <!--<el-menu-item index="/logsView" v-if="rzview == 'T'">
                            <i class="el-icon-tickets"></i>
                            <span slot="title">日志查看</span>
                        </el-menu-item> -->
                      </el-menu>
                </el-col>
            </el-aside>
            <el-container>
                <el-header>
                  <div @click="handleCollpse" style="display:flex;justify-content:center;align-items:center">
                    <img src="../../icons/images/caidan.png" alt="">
                    <span style="margin-left:20px;font-size:16px;" v-if="qihuantabdhspan == '设计'">展 示 页 面</span>
                    <span style="margin-left:20px;font-size:16px;" v-if="qihuantabdhspan == '展示'">设 计 页 面</span>
                  </div>
                  <div class="caozuo">
                    <!-- <span class="el-icon-back"></span>
                    <span style="cursor:pointer" @click="handleLogout">退出登录</span> -->
                    <button @click="qihuantabdh">{{ qihuantabdhspan }}</button>
                    <button @click="handleLogout">退出登录</button>
                  </div>
                </el-header>
                <!-- <Y-tag :tags="tags" @tagsDel="tagDel" :noClose="'/home'" /> -->
                <el-main>
                    <router-view :dataEchart="dataEchart" :allDataEchart="allDataEchart" :teamids="teamids"></router-view>
                </el-main>
            </el-container>
        </el-container>
      </div>
      <div v-if="!pcEquipment" class="moveHome">
        <div class="headerBox">
          <span>汇信云商BI</span>
        </div>
        <div class="actionBox">
          <van-collapse v-model="activeName" accordion >
            <van-collapse-item v-for="(item, index) in routerdata" :title="item.catName" class="farterBox" :key="index" :name="index" size="1rem">
              <div v-for="(iteam, index1) in item.children" :key="index1" class="selectBox" @click="selectBoxClick(iteam)">{{ iteam.catName }}</div>
            </van-collapse-item>
          </van-collapse>
          <!-- <div style="margin-bottom: 2.66667rem"></div> -->
        </div>
      </div>
    </div>
</template>
<script>
import jsCookie from 'js-cookie';
import { mapState, mapActions } from "vuex";
import { getSession, removeSession, setSession, setLocal, getLocal } from '@/utils/methods';
import { getLargeCategory } from "@/api/chart/index.js"
import { getdataSetlist, getChartData } from "@/api/dataSet/index.js"
  
export default {
    name: 'Home',
    data () {
        return{
          activeName: ['0'],
          collapse: false,
          width: '200px',
          defaultOpeneds: ["/echarshtlm", "/databaseLink"],
          rzview: "",
          logospandisabled: true,
          logoboxttd: 'logobox',
          qihuantabdhspan: "设计",
          qihuadisabled: true,
          isTagTrue: false,
          routerdata: [],
          dataEchart: {},
          allDataEchart: [],
          pathRoutar: "",
          pcEquipment: false,
          datasetId: '',
          teamids: '',
        }
    },
    watch: {
      $route() {
        const { name, fullPath, hash, meta, params, path, query } = this.$route;
        if (name) {
          let obj = {
            name,
            fullPath,
            hash,
            meta,
            params,
            path,
            query,
          };
          this.tagsAdd(obj);
        }
      },
    },
    computed: {
      ...mapState({
        tags: (state) => state.global.tags,
      }),
    },
    methods: {
        ...mapActions({
          tagsAdd: "global/TAG_ADD",
          tagsDel: "global/TAG_DEL",
          tagsDelAll: 'global/TAG_DELALL'
        }),
        selectBoxClick (iteam) {
          this.$router.push({
            name: 'moveCharts',
            params: {
              id: iteam.id,
              name: iteam.catName
            }
          })
        },
        init() {
          this.getqihuantabdhspan()
          // 刷新模块页面重定位
          this.rzview = jsCookie.get("tokenrzview")
          let path = this.$route.path;
          this.navConfig = [
              {index:'1',path:['/echarshtlm','/tableView']},
          ];
          let thisNav = this.navConfig.find(item =>{
              return item.path.includes(path);
          });
          if(thisNav) {
            this.defaultOpeneds = [thisNav.index];
          }
          this.getLargeCategoryjuoqu()
        },
        browserRedirect() {
          this.pcEquipment = this.$pcEquipment
        },
        clickedsa (team) {
          getChartData({datasetId: team.id}).then(res => {
            if(res.length) {
              // 默认展示数据集第一条图表
              setLocal("heightList", res[0].canvasHeight)
              setLocal("teamID", res[0].id)
              setLocal("datasetId", res[0].datasetId)
              setLocal("catName", res[0].catName)
              this.dataEchart = res[0]
              this.allDataEchart = res
              this.teamids = ""
            }else {
              let data = ""
              setLocal("teamID", data)
              this.dataEchart = ""
              this.allDataEchart = [],
              this.teamids = team.id
              // this.$message({
              //   message: '该数据集下没有数据',
              //   type: 'warning'
              // });
            }
   
          })
          
        },
        getLargeCategoryjuoqu () {
          /* 现在路由 */
          getdataSetlist().then(res => {
            this.routerdata = res
            if (getLocal("teamID")) {
              this.$nextTick(() => {
                let teamID = JSON.parse(getLocal("teamID"))
                this.$route.path = teamID
              })
            }
            //
            this.datasetId = JSON.parse(getLocal("datasetId")) || res[0].id

            let obj = {id: this.datasetId}
            this.clickedsa(obj)
          })


          /* 以往的路由 */
          // getLargeCategory().then(res => {
          //   console.log('res', res)
          //   let count = 0
          //   for (let i = 0; i < res.length; i ++) {
          //     if (res[i].children && Array.isArray(res[i].children)) {
          //       for (let j = 0; j < res[i].children.length; j ++) {
          //         res[i].children[j].path = "/echarshtlm" + count
          //         console.log('count', count)
          //         count ++
          //       }
          //     }
          //   }
          //   // this.$router.addRoutes(routeradd)
          //   this.routerdata = res
          //   if (getLocal("teamID")) {
          //     this.$nextTick(() => {
          //       let teamID = JSON.parse(getLocal("teamID"))
          //       this.$route.path = teamID
          //     })
          //   }
          // })
        },
        handleOpen () {},
        handleClose () {},
        handleCollpse() {
          if(this.collapse) {
            this.collapse = false
            this.$nextTick(() => {
              this.width = "200px"
            })
            this.logospandisabled = true
            this.logoboxttd = "logobox"
          }else {
            this.collapse = true
            this.$nextTick(() => {
              this.width = "60px"
            })
            this.logospandisabled = false
            this.logoboxttd = "logoboxto"
          }
        },
        // 退出登录
        handleLogout() {
          jsCookie.remove('token')
          jsCookie.remove('userDetails')
          this.$router.replace("/login");
        },
        qihuantabdh () {
          if (this.qihuantabdhspan == "设计") {
            this.tagsDelAll()
            this.qihuantabdhspan = "展示"
            this.qihuadisabled = false
            this.$router.push({
              name:'databaseLink'
            })
          } else {
            this.qihuantabdhspan = "设计"
            this.tagsDelAll()
            this.qihuadisabled = true
            this.$router.push({
              name:'Home'
            })
            // this.getLargeCategoryjuoqu()
            this.init()
          }
          setLocal("qihuantabdhspan", this.qihuantabdhspan)
        },
        getqihuantabdhspan () {
          this.$nextTick(() => {
            let routerType = getLocal("qihuantabdhspan")
            let newrouterType = JSON.parse(routerType)
            // this.qihuantabdhspan = routerType
            if (newrouterType == "设计") {
              this.qihuantabdhspan = "设计"
              this.qihuadisabled = true
              this.$router.push({
                name:'Home'
              })
              this.getLargeCategoryjuoqu()
              
            } else if (newrouterType == "展示") {
              this.qihuantabdhspan = "展示"
              this.qihuadisabled = false
              this.$router.push({
                name:'databaseLink'
              })
            }
          })
        },
        tagDel(tag) {
          this.handleDel(tag);
        },
        handleDel(tag) {
          let tags = this.tags;
          let path = this.$route.path;
          let tagStep = getSession("tagStep");
          if (tagStep == tag.fullPath) {
            removeSession("tagStep");
          }

          if (tag.path === path) {
            tags.forEach((item) => {
              if (item.fullPath == tagStep) {
                this.isTagTrue = true;
              }
            });
            if (tagStep && tag.fullPath != tagStep && this.isTagTrue) {
              this.isTagTrue = false;
              this.$router.push({ path: tagStep });
            } else {
              tags.forEach((tag, index) => {
                if (tag.path === path) {
                  let nextTag = tags[index + 1] || tags[index - 1];
                  if (nextTag) {
                    let { name, fullPath } = nextTag;
                    this.$router.push({ path: fullPath });
                  }
                }
              });
            }
          }
          this.tagsDel({
            routePath: tag.path,
          });
        },
    },
    created () {
      this.browserRedirect()
      console.log("userNameuserName", jsCookie.get('userName'))
    },
    mounted(){
      this.init()
    }
}
</script>
<style scoped lang="less" scoped>
@font-size-base: 75;
.home{
    width: 100%;
    height: 100%;
    .homesun {
      height: 100%;
      width: 100%;
    }
}
.logobox {
  background: url("../../assets/logo.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  text-align: center;
}
.logoboxto {
  background: url("../../assets/logo.jpg");
  // background-repeat: no-repeat;
  background-size: 100%;
  text-align: center;
}
.logospan {
  font-size: 24px;
  color: white;
}
.el-header, .el-footer {
  background-color: #ffffff;
  color: #333;
  /* text-align: center;
  line-height: 60px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-menu {
  border: none;
}
.el-aside {
  background-color: #304156;
  color: #333;
  text-align: start;
  line-height: 60px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  padding: 10px !important;
  flex-basis: 0px;
  /* text-align: center; */
  /* line-height: 160px; */
}
.el-container {
  height: 100%;
}
body > .el-container {
  margin-bottom: 40px;
}
.caozuo {
  button {
    margin: 0 5px;
    height: 30px;
    width: 100px;
    font-weight: 600;
    background: white;
    border-radius: 4px;
    border: 1px solid rgb(152, 152, 152);
  }
}
/deep/ .el-table .el-table__cell {
  padding: 6px;
}
/deep/ .el-button {
  padding: 6px;
}
.moveHome {
  width: 100%;
  height: 100%;
  // padding-top: 20%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .headerBox {
    // position: absolute;
    // top: 0;
    width: 10rem;
    height: 1.4rem;
    background: rgb(87,173,247);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: .466667rem;
    font-weight: 600;
 
  }
  // .actionBox {
  //   flex: 1;
  //   // width: 100%;
  //   // height: 100%;
  //   overflow: hidden;
  //   overflow-y: scroll;
  //   div {
  //     font-size: .373333rem;
  //   }
  //   /deep/.el-collapse-item__header {
  //     font-size: .373333rem;
  //   }
  // }
}
.selectBox {
  width: 100%;
  border-top: 0.5px solid #d4d4d5;
  // border-bottom: 0.5px solid #b3b4b5;
  padding: 10px 0;
  text-align: center;
  font-size: .38rem;
}
/deep/ .el-collapse-item__header {
  justify-content: center;
}
// .farterBox {
//   position: relative;
// }
/deep/ .el-collapse-item__arrow {
  position: absolute;
  left: 10px;
  margin: 0;
}
/deep/ .el-collapse-item__content {
  padding: 0;
}
/deep/.van-cell__title {
  font-size: .38rem;
  height: .7rem;
  display: flex;
  align-items: center;
}
/deep/.van-cell__right-icon{
  height: .7rem;
  font-size: .38rem;
  display: flex;
  align-items: center;
}
// /deep/.farterBox {
//   font-size: 3rem;
// }
/deep/.van-collapse-item__content{
  padding: 0;
}
</style>