<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import jsCookie from "js-cookie";
import { tokenOrToken } from "../src/api/bisystem/bisystem"
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      myToken: ''
    }
  },
  // beforeCreate () {
  //   window.addEventListener('message', event => {
  //     let data = event.data;
  //     switch (data.iframeClick) {
  //       case 'getParams':
  //         this.myToken = data.params.key; // 这样就拿到了vue传过来的params
  //         // jsCookie.set("token", res.token);
  //         let dataeds = {
  //           sysCode: "bs",
  //           token: this.myToken
  //         }
  //         if (!jsCookie.get("token")) {
  //           tokenOrToken(dataeds).then(res => {
  //             jsCookie.set("token", res.token);
  //             this.$router.push({
  //               name: 'echarshtlm'
  //             })
  //           })
  //         } else {
  //           this.$router.push({
  //             name: 'echarshtlm'
  //           })
  //         }
  //       break;
  //     }
  //   });
  // },
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
html, body, #app {
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: 'iconfont';  /* Project id 2866956 */
  src: url('//at.alicdn.com/t/font_2866956_6o44gkm1ajq.woff2?t=1634179937997') format('woff2'),
       url('//at.alicdn.com/t/font_2866956_6o44gkm1ajq.woff?t=1634179937997') format('woff'),
       url('//at.alicdn.com/t/font_2866956_6o44gkm1ajq.ttf?t=1634179937997') format('truetype');
}
.iconfont{
  font-family:"iconfont" !important;
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.namespandiv {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  height: 40px;
}
.tabbox {
  width: 100%;
  height: 450px;
}
</style>
