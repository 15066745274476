<template>
  <div class="tags-bar">
    <div slot="left-btn" class="btns_nav btn-left" @click="scrollPrev">
      <i class="el-icon-arrow-left"></i>
    </div>

    <div class="scrollTag" ref="scrollTag">
      <div class="tags" ref="tags" :style="navStyle">
        <!-- <transition-group name="tags"> -->
        <router-link
          v-for="tag in tags"
          :key="tag.path"
          :class="tag.path == $route.path ? 'active' : ''"
          class="tags-item"
          :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        >
          <span @click="handleTag(tag)">{{ tag.title }}</span>
          <!-- {{tag.title}} -->
          <span
            v-if="tag.path !== noClose"
            class="el-icon-close"
            @click.prevent.stop="tagsDel(tag)"
          ></span>
        </router-link>
        <!-- </transition-group> -->
      </div>
    </div>
    <div slot="rigth-btn" class="btns_nav btn-right" @click="scrollNext">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "Y-tag",
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    noClose: String,
  },
  data() {
    return {
      offset: 0,
      scrollable: true,
    };
  },
  computed: {
    navStyle() {
      return { transform: `translate(-${this.offset + "px"},0px)` };
    },
  },
  created() {},
  mounted() {},
  watch: {
    tags() {
      this.$nextTick((_) => this.update());
    },
    $route() {
      this.$nextTick((_) => this.scrollToActiveTag());
    },
  },
  methods: {
    update() {
      if (!this.$refs.tags) return;
      const navSize = this.$refs.tags.offsetWidth;
      const containerSize = this.$refs.scrollTag.offsetWidth;
      const currentOffset = this.offset;
      if (containerSize < navSize) {
        if (navSize - currentOffset < containerSize) {
          this.offset = navSize - containerSize;
        }
      } else {
        if (currentOffset > 0) {
          this.offset = 0;
        }
      }
    },
    scrollToActiveTag() {
      const tags = this.$refs.tags;
      const activeTag = this.$el.querySelector(".active");
      if (!activeTag) return;
      const scrollTag = this.$refs.scrollTag;
      const activeTagBounding = activeTag.getBoundingClientRect();
      const scrollTagBounding = scrollTag.getBoundingClientRect();

      const curOffset = this.offset;

      let newOffset = curOffset;
      if (activeTagBounding.right > scrollTagBounding.right) {
        newOffset =
          newOffset + (activeTagBounding.right - scrollTagBounding.right);
      }
      if (activeTagBounding.left < scrollTagBounding.left) {
        newOffset =
          curOffset - (scrollTagBounding.left - activeTagBounding.left);
      }
      newOffset = Math.max(newOffset, 0);
      this.offset = newOffset;
    },
    tagsDel(tag) {
      this.$emit("tagsDel", tag);
    },
    handleTag(tag) {
      this.$emit("handleTag", tag);
    },
    scrollPrev() {
      const containerSize = this.$refs.scrollTag.offsetWidth;
      const curOffset = this.offset;
      if (!curOffset) return;
      let newOffset = curOffset > containerSize ? curOffset - containerSize : 0;
      this.offset = newOffset;
      this.offset = 0;
    },
    scrollNext() {
      const navSize = this.$refs.tags.offsetWidth;
      const containerSize = this.$refs.scrollTag.offsetWidth;
      const currentOffset = this.offset;
      if (navSize - currentOffset <= containerSize) return;

      let newOffset =
        navSize - currentOffset > containerSize * 2
          ? currentOffset + containerSize
          : navSize - containerSize;

      this.offset = newOffset;
    },
  },
  mounted () {
    setTimeout(() => {
      this.scrollToActiveTag();
    }, 0);
  },
  updated() {},
  components: {},
};
</script>

<style scoped lang="less">
.tags-bar {
  width: 100%;
  height: 34px;
  display: flex;
  overflow: hidden;
  background-color: #fff;
  .scrollTag {
    flex: 1;
    overflow: hidden;
    .tags {
      display: inline-block;
      white-space: nowrap;
      line-height: 34px;
      transition: all 0.3s ease;
      .tags-item {
        padding: 6px 8px;
        margin-right: 4px;
        font-size: 12px;
        background-color: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 4px;
        position: relative;
        &:not(.active):after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 2px;
          background: #1397fb;
          transition: width 0.3s ease;
        }
        &:not(.active):hover::after {
          width: 100%;
          height: 3px;
          background: #1397fb;
        }
        .el-icon-close {
          color: #909399;
          width: 14px;
          height: 14px;
          text-align: center;
          line-height: 14px;
          &:hover {
            color: #fff;
            background-color: #909399;
            border-radius: 50%;
          }
        }
      }

      .active {
        color: #409eff;
        box-shadow: 1px 0 2px #909399;
      }
    }
  }
  .btns_nav {
    padding: 0 4px;
    width: 30px;
    text-align: center;
    z-index: 9;
    background-color: #fff;
    overflow: hidden;
    font-size: 20px;
    line-height: 34px;
    &:hover {
      color: #1397fb;
      cursor: pointer;
    }
    box-shadow: 0 0 10px #888888;
  }
}
</style>
