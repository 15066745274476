<template>
    <div class="charbB">
        <div :id="webChartsId"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    name: 'Y-charbBBOX',
    props: {
        chartsData: {
            type: Array,
            required: [],
        },
        webChartsId: {
            type: String,
            required: "",
        },
    },
    data () {
        return{
            echartsBoxId: "",
            columns: [],
        }
    },
    watch: {
        chartsData: {
            handler (val) {
                this.columns = val
                this.dataProcessing(this.columns)
            },
            immediate: true,
            deep: true
        },
    },
    methods: {
        dataProcessing (newChartsData) {
            let option
            let opyionsData = newChartsData
            let dataList = []
            for (let i = 0; i < opyionsData.length; i ++) {
                let newdata = {
                    value: opyionsData[i].value,
                    name: opyionsData[i].name,
                }
                dataList.push(newdata)
            }
            option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    top: 20,
                    bottom: 20,
                    right:  10,
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '80%',
                        center: ['20%' , '50%'],
                        data: dataList,
                    }
                ]
            }
            this.echartsBoxId = this.webChartsId
            this.$nextTick(() => {
                this.dataRendering(option, this.echartsBoxId)
            })
        },
        dataRendering (options, id) {
            let chartDom = document.getElementById(id);
            let myChart = echarts.init(chartDom);
            let option = options
            option && myChart.setOption(option);
        },
    },
    created () {
        this.dataProcessing(this.chartsData)
    },
}
</script>
<style lang="less" scoped>
@font-size-base: 75;
.charbB {
    width: 10rem;
    height: 10rem;
    div {
        width: 100%;
        height: 100%;
    }
}
</style>