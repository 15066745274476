import { render, staticRenderFns } from "./charbB.vue?vue&type=template&id=77e66bc8&scoped=true&"
import script from "./charbB.vue?vue&type=script&lang=js&"
export * from "./charbB.vue?vue&type=script&lang=js&"
import style0 from "./charbB.vue?vue&type=style&index=0&id=77e66bc8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77e66bc8",
  null
  
)

export default component.exports