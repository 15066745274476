import Vue from "vue";
import Vuex from "vuex";
import global from "./global";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global,
  },
  state: {
    dataVisualization: {
      dataEcharts: {
        histogram : [], // 柱状图数组
        histogramIndex : 0, // 当前柱状图索引
        charHB : [], // 饼状图数组
        charHBIndex : 0, // 饼状图索引
        charDSJZ: [],     // 多对比树状图
        charDSJZIndex:0, // 多对比下标
        lineChart: [],    // 折线图
        lineChartIndex: 0, // 折线图下标
        lineHistogram: [],  // 折柱混合
        lineHistogramIndex: 0,// 折柱混合下标
        linkageShare: [],  // 联动和共享数据集
        linkageShareIndex: 0,   // 联动和共享数据集下标
        charbB: [],
        charbBIndex:0,
      },
      dataBase: { // 后期补充基础元素的拖拽

      }
    }
  },
  getters: {
    getDataEchartsItem: (state) => ({type= 'histogram',id = null } = {}) => {
      if(id === null) return {};
      return state.dataVisualization.dataEcharts[type].find(item => item.id === id);
    },
    getDataEchartsIndex: (state) =>({type= 'histogram'} = {}) => {
      return state.dataVisualization.dataEcharts[type+'Index'];
    },
  },
  mutations: {
    addDataEchartsItem(state,{type = 'histogram',option = 'option'} = {}){
      state.dataVisualization.dataEcharts[type].push(option);
    },
    addDataEchartsIndex(state,{type = 'histogram'} = {}){
      state.dataVisualization.dataEcharts[type+'Index'] += 1;
    }
  },
  actions: {},
});
