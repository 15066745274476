<template>
<!-- 移动端 -->
  <div class="searchForm">
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }" > 数据源筛选 </van-divider>
    <!-- <Y-input :inputName="'输入框'" :inputValue="form.inputValue" @input="(val) => input(val, 'form', 'inputValue')"></Y-input>
    <Y-selectInout :inputName="'数据源'" :inputValue="form.selectValue" :columns="columns" @onConfirmSelect="(val) => input(val, 'form', 'selectValue')"></Y-selectInout>
    <Y-dateInput :inputValue="form.time" @onConfirm="(date) => onConfirm(date, 'form', 'time')"></Y-dateInput> -->
    <!-- 通用数据源 -->
    <Y-selectInout :inputName="'数据源'" :inputValue="selectValue" :columns="columns" @onConfirmSelect="(val) => selectInput(val)"></Y-selectInout>
    <!-- 普通柱状图----histogram -->
    <Y-selectInout v-if="chartsType == 'histogram'" :inputName="'横坐标'" :inputValue="histogramForm.row" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'histogramForm', 'row')"></Y-selectInout>
    <Y-selectInout v-if="chartsType == 'histogram'" :inputName="'纵坐标'" :inputValue="histogramForm.col" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'histogramForm', 'col')"></Y-selectInout>
    <!-- 多数据对比柱状图----charDSJZ -->
    <Y-selectInout v-if="chartsType == 'charDSJZ'" :inputName="'横坐标'" :inputValue="charDSJZForm.row" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'charDSJZForm', 'row')"></Y-selectInout>
    <Y-selectInout v-if="chartsType == 'charDSJZ'" :inputName="'纵坐标'" :inputValue="charDSJZForm.col" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'charDSJZForm', 'col')"></Y-selectInout>
    <Y-selectInout v-if="chartsType == 'charDSJZ'" :inputName="'数据分组'" :inputValue="charDSJZForm.cat" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'charDSJZForm', 'cat')"></Y-selectInout>
    <!-- 折线图----lineChart -->
    <Y-selectInout v-if="chartsType == 'lineChart'" :inputName="'横坐标'" :inputValue="lineChartForm.row" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'lineChartForm', 'row')"></Y-selectInout>
    <Y-selectInout v-if="chartsType == 'lineChart'" :inputName="'纵坐标'" :inputValue="lineChartForm.col" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'lineChartForm', 'col')"></Y-selectInout>
    <!-- 折柱混合----lineHistogram -->
    <Y-selectInout v-if="chartsType == 'lineHistogram'" :inputName="'横坐标字段'" :inputValue="lineHistogramForm.row" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'lineHistogramForm', 'row')"></Y-selectInout>
    <Y-selectsInout v-if="chartsType == 'lineHistogram'" :inputName="'柱图纵坐标'" :inputValue="lineHistogramForm.bar" :columns="columnsSun" @confirm="(val) => confirm(val, 'lineHistogramForm', 'bar')"></Y-selectsInout>
    <Y-selectsInout v-if="chartsType == 'lineHistogram'" :inputName="'折图纵坐标'" :inputValue="lineHistogramForm.line" :columns="columnsSun" @confirm="(val) => confirm(val, 'lineHistogramForm', 'line')"></Y-selectsInout>
    <!-- 普通饼状图----charbB -->
    <Y-selectInout v-if="chartsType == 'charbB'" :inputName="'横坐标'" :inputValue="charbBForm.row" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'charbBForm', 'row')"></Y-selectInout>
    <Y-selectInout v-if="chartsType == 'charbB'" :inputName="'纵坐标'" :inputValue="charbBForm.col" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'charbBForm', 'col')"></Y-selectInout>
    <!-- 联动和共享数据集----linkageShare -->
    <Y-selectInout v-if="chartsType == 'linkageShare'" :inputName="'横坐标'" :inputValue="linkageShareForm.row" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'linkageShareForm', 'row')"></Y-selectInout>
    <Y-selectInout v-if="chartsType == 'linkageShare'" :inputName="'纵坐标'" :inputValue="linkageShareForm.col" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'linkageShareForm', 'col')"></Y-selectInout>
    <Y-selectInout v-if="chartsType == 'linkageShare'" :inputName="'数据分组'" :inputValue="linkageShareForm.cat" :columns="columnsSun" @onConfirmSelect="(val) => selectSun(val, 'linkageShareForm', 'cat')"></Y-selectInout>
    <!-- 弹窗选择项 -->
    <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="screeningClick">筛选</van-button>
    </div>
    <van-divider v-if="inputarr.length > 0" :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }" > 条件搜索 </van-divider>
    <div class="inputsearch" v-for="(item, index) in inputarr" :key="index">
        <!-- {{ item }} -->
        <Y-input v-if="item.inputType == 'string'" :inputName="item.showName" :inputValue="item.value" @input="(val) => selectionInput(val, index)"></Y-input>
        <Y-dateInput v-if="item.inputType == 'date'" :inputValue="item.time" @onConfirm="(date) => selectionOnConfirm(date, index)"></Y-dateInput>
    </div>
    <div style="margin: 16px;" v-if="inputarr.length > 0">
        <van-button round block type="info" native-type="submit" @click="searButton">搜索</van-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import clone from 'clone'
import { getAbscissa } from "@/api/catConfig"
export default {
    name: 'Y-searchForm',
    props: {
        chartsType: {
            type: String,
            default: ""
        },
        filterPropList: {
            type: Array,
            default: []
        },
        chartSet: {
            type: Object,
            default: {}
        },
        dataSourceOption: {
            type: Array,
            default: []
        },
    },
    watch: {
        dataSourceOption: {
            handler (val) {
                this.columns = val
            },
            immediate: true,
            deep: true
        },
        chartSet: {
            handler (val) {
                this.chartSetTransfer(val)
            }
        },
        chartsType: {
            handler (val) {
                this.charType = val
            }
        },
        filterPropList: {
            handler (val) {
                this.filPropList = val
                this.selectionInpur(val)
            }
        },
    },
    data () {
        return {
            columns: [],
            columnsSun: [],
            charType: '',
            filPropList: [],
            inputarr: [],
            selectValue: {
                text: '',
                value: ''
            },
            histogramForm: {
                row: {
                    text: '',
                    value: ''
                },
                col: {
                    text: '',
                    value: ''
                },
            },
            charDSJZForm: {
                row: {
                    text: '',
                    value: ''
                },
                col: {
                    text: '',
                    value: ''
                },
                cat: {
                    text: '',
                    value: ''
                },
            },
            lineChartForm: {
                row: {
                    text: '',
                    value: ''
                },
                col: {
                    text: '',
                    value: ''
                },
            },
            lineHistogramForm: {
                row: {
                    text: '',
                    value: ''
                },
                bar: [],
                line: []
            },
            charbBForm: {
                row: {
                    text: '',
                    value: ''
                },
                col: {
                    text: '',
                    value: ''
                },
            },
            linkageShareForm: {
                row: {
                    text: '',
                    value: ''
                },
                col: {
                    text: '',
                    value: ''
                },
                cat: {
                    text: '',
                    value: ''
                },
            },
            form: {
                inputValue: '',
                selectValue: {
                    text: '',
                    value: ''
                },
                time: {
                    startDate: '',
                    endDate: ''
                }
            }
        }
    },
    created () {
        this.chartSetTransfer(this.chartSet)
        this.selectionInpur(this.filterPropList)
    },
    methods: {
        // 更改数据源操作
        selectInput (val) {
            if (this.selectValue.text !== val.text) {
                if (this.chartsType == 'histogram') {
                    this.histogramForm = {
                        row: {
                            text: '',
                            value: ''
                        },
                        col: {
                            text: '',
                            value: ''
                        },
                    }
                } else if (this.chartsType == 'charDSJZ') {
                    this.charDSJZForm = {
                        row: {
                            text: '',
                            value: ''
                        },
                        col: {
                            text: '',
                            value: ''
                        },
                        cat: {
                            text: '',
                            value: ''
                        },
                    }
                } else if (this.chartsType == 'lineChart') {
                    this.lineChartForm = {
                        row: {
                            text: '',
                            value: ''
                        },
                        col: {
                            text: '',
                            value: ''
                        },
                    }
                } else if (this.chartsType == 'lineHistogram') {
                    this.lineHistogramForm = {
                        row: {
                            text: '',
                            value: ''
                        },
                        bar: [],
                        line: []
                    }
                } else if (this.chartsType == 'charbB') {
                    this.charbBForm = {
                        row: {
                            text: '',
                            value: ''
                        },
                        col: {
                            text: '',
                            value: ''
                        },
                    }
                } else if (this.chartsType == 'linkageShare') {
                    this.linkageShareForm = {
                        row: {
                            text: '',
                            value: ''
                        },
                        col: {
                            text: '',
                            value: ''
                        },
                        cat: {
                            text: '',
                            value: ''
                        },
                    }
                }
            }
            this.selectValue = val
            getAbscissa({dsId: val.value}).then(res => {
                let selectionArr = []
                for (let i = 0; i < res.length; i ++) {
                    let data = {
                        text: res[i].showName,
                        value: res[i].fname
                    }
                    selectionArr.push(data)
                }
                // this.abscissaOption = res
                this.columnsSun = selectionArr
            })
        },
        selectionInpur (data) {
            // let data = this.filterPropList
            let newDate = clone(data)
            for (let i = 0; i < newDate.length; i ++) {
                if (newDate[i].ftype == '2') {
                    newDate[i].inputType = 'date'
                    newDate[i].time = {
                        startDate: '',
                        endDate: '',
                    }
                } else {
                    newDate[i].inputType = 'string'
                }
                if (newDate[i].value == "" || newDate[i].value == null || newDate[i].value == undefined) {
                    newDate[i].value = ""
                }
            }
            this.inputarr = newDate
        },
        // 更改其他下拉
        selectSun (val, form, value) {
            this[form][value] = val
        },
        confirm (val, form, value) {
            this[form][value] = val
        },
        // 筛选按钮
        screeningClick () {
            let data = {}
            if (this.chartsType == 'histogram') {
                for (let i in this.histogramForm) {
                    data[i] = this.histogramForm[i].value
                }
            } else if (this.chartsType == 'charDSJZ') {
                for (let i in this.charDSJZForm) {
                    data[i] = this.charDSJZForm[i].value
                }
            } else if (this.chartsType == 'lineChart') {
                for (let i in this.lineChartForm) {
                    data[i] = this.lineChartForm[i].value
                }
            } else if (this.chartsType == 'lineHistogram') {
                for (let i in this.lineHistogramForm) {
                    if (this.lineHistogramForm[i] instanceof Array) {
                        data[i] = this.lineHistogramForm[i]
                    } else {
                        data[i] = this.lineHistogramForm[i].value
                    }
                }
            } else if (this.chartsType == 'charbB') {
                for (let i in this.charbBForm) {
                    data[i] = this.charbBForm[i].value
                }
            } else if (this.chartsType == 'linkageShare') {
                for (let i in this.linkageShareForm) {
                    data[i] = this.linkageShareForm[i].value
                }
            }
            data.datasetId = this.selectValue.value
            this.$emit("screeningClick", data)
        },
        searButton () {
            this.$emit("searButton", this.inputarr)
        },
        // 父组件数据更改监听操作
        chartSetTransfer (val) {
            let valid = val.datasetId
            for (let i = 0; i < this.dataSourceOption.length; i ++) {
                if (valid == this.dataSourceOption[i].value) {
                    this.selectValue = this.dataSourceOption[i]
                    getAbscissa({dsId: this.dataSourceOption[i].value}).then(res => {
                        let selectionArr = []
                        for (let i = 0; i < res.length; i ++) {
                            let data = {
                                text: res[i].showName,
                                value: res[i].fname
                            }
                            selectionArr.push(data)
                        }
                        // this.abscissaOption = res
                        this.columnsSun = selectionArr
                        let newVal = clone(val)
                        delete newVal.datasetId
                        let newForm = {}
                        for (let i in newVal) {
                            if (newVal[i] instanceof Array) {
                                newForm[i] = newVal[i]
                            } else {
                                for (let j = 0; j < this.columnsSun.length; j ++) {
                                    if (this.columnsSun[j].value == newVal[i]) {
                                        newForm[i] = this.columnsSun[j]
                                        break;
                                    }
                                }
                            }
                        }
                        if (this.chartsType === 'histogram') {
                            this.histogramForm = newForm
                        } else if (this.chartsType === 'charDSJZ') {
                            this.charDSJZForm = newForm
                        } else if (this.chartsType === 'lineChart') {
                            this.lineChartForm = newForm
                        } else if (this.chartsType === 'lineHistogram') {
                            this.lineHistogramForm = newForm
                        } else if (this.chartsType === 'charbB') {
                            this.charbBForm = newForm
                        } else if (this.chartsType === 'linkageShare') {
                            this.linkageShareForm = newForm
                        }
                    })
                    break;
                }
            }
        },
        selectionInput (val, index) {
            this.inputarr[index].value = val
        },
        selectionOnConfirm (date, index) {
            this.inputarr[index].time = date
        },
        // input (val, form, value) {
        //     this[form][value] = val
        // },
        // onConfirm (date, form, value) {
        //     let obj = {
        //         startDate: moment(date[0]).format('YYYY-MM-DD'),
        //         endDate: moment(date[1]).format('YYYY-MM-DD')
        //     }
        //     this[form][value] = obj
        // },
    },
    mounted () {
    }
}
</script>
<style scoped lang="less">
@font-size-base: 75;
.searchForm {
    width: 100%;
    height: 100%;
}
.demo-ruleForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.formBox {
    width: 100%;
    flex: 1;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    .leftBox {
        flex: 1;
        padding: .066667rem;
        box-sizing: border-box;
    }
    .rightBox {
        flex: 1;
        padding: .066667rem;
        box-sizing: border-box;
    }
}
.el-input {
    margin: .066667rem 0;
}
.inputsearch {
    width: 100%;
}
</style>
