<template>
    <div class="histogram">
        <div :id="webChartsId"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import clone from "clone"
export default {
    name: 'Y-histogramBOX',
    props: {
        chartsData: {
            type: Array,
            required: [],
        },
        webChartsId: {
            type: String,
            required: "",
        },
    },
    data () {
        return{
            echartsBoxId: "",
            columns: []
        }
    },
    watch: {
        chartsData: {
            handler (val) {
                this.columns = val
                this.dataProcessing(this.columns)
            },
            immediate: true,
            deep: true
        },
    },
    methods: {
        dataProcessing (newChartsData) {
            let opyionsData = newChartsData
            let data1 = []
            let data2 = []
            for (let i = 0; i < opyionsData.length; i ++) {
                data1.push(opyionsData[i].name)
                data2.push(opyionsData[i].value)
            }
            let option = {
                tooltip: {},
                xAxis: {
                    data: data1,
                    axisLabel: {
                        interval: 0,
                        rotate: 30,
                        formatter: function(value) {
                            if (value.length > 6) {
                                return value.substring(0, 7) + "...";
                            } else {
                                return value;
                            }
                        }
                    },
                },
                yAxis: {
                    axisLabel: {
                        color: "#000",
                        interval: 0,
                        formatter: function(value) {
                            if (value >= 100 && value < 1000) {
                                return (value / 100) + "百"
                            } else if (value >= 1000 && value < 10000) {
                                return (value / 1000) + "千"
                            } else if (value >= 10000 && value < 100000) {
                                return (value / 10000) + "万"
                            } else if (value >= 100000 && value < 1000000) {
                                return (value / 100000) + "十万"
                            } else if (value >= 1000000 && value < 10000000) {
                                return (value / 1000000) + "百万"
                            } else if (value >= 10000000 && value < 100000000) {
                                return (value / 10000000) + "千万"
                            } else if (value >= 100000000 && value < 1000000000) {
                                return (value / 100000000) + "亿"
                            } else if (value >= 1000000000 && value < 10000000000) {
                                return (value / 1000000000) + "十亿"
                            } else if (value >= 10000000000 && value < 100000000000) {
                                return (value / 10000000000) + "百亿"
                            } else if (value >= 100000000000 && value < 1000000000000) {
                                return (value / 100000000000) + "千亿"
                            } else {
                                return value
                            }
                        }
                    },
                },
                series: [{
                    type: 'bar',
                    data: data2
                }]
            }
            this.echartsBoxId = this.webChartsId
            this.$nextTick(() => {
                this.dataRendering(option, this.echartsBoxId)
            })
        },
        dataRendering (options, id) {
            let chartDom = document.getElementById(id);
            let myChart = echarts.init(chartDom);
            let option = options
            option && myChart.setOption(option);
        },
    },
    mounted () {
        this.dataProcessing(this.chartsData)
    },
}
// {
//     title: {
//         text: ' HUIXINBI'
//     },
//     tooltip: {},
//     xAxis: {
//         data: ["衬衫","羊毛衫","雪纺衫","裤子","高跟鞋","袜子"]
//     },
//     yAxis: {},
//     series: [{
//         type: 'bar',
//         data: [5, 20, 36, 10, 10, 20]
//     }]
// };
</script>
<style lang="less" scoped>
@font-size-base: 75;
.histogram {
    width: 10rem;
    height: 10rem;
    div {
        width: 100%;
        height: 100%;
    }
}
</style>