import Vue from 'vue';
import axios from 'axios';
import { Message } from 'element-ui';
import jsCookie from 'js-cookie';
// import { getToken } from "@/utils/auth";
import { cancelApi, myLoading } from '@/utils/myApi';
let Loading = new myLoading('#app');
let CancelApi = new cancelApi();
Vue.prototype.CancelApi = CancelApi;
let baseURL = process.env.VUE_APP_BASE_API;

// if (baseURL === '/pro') {
// 	baseURL = 'http://125.38.249.178:8967';
// }
// bi部署地址
baseURL = 'https://bi.winseeing.com.cn'
const server = axios.create({
	baseURL: baseURL,
	timeout: 50000,
});

server.interceptors.request.use(
	config => {
		CancelApi.remove(config);
		CancelApi.add(config);
		// config.headers.Authorization = getToken() || "";
		// let reportFlag = window.opener['reportFlag']
		config.headers.Authorization = jsCookie.get('token') || '';
		if (config.url === '/route/route') {
			Loading.start('正在初始化请稍等...');
		} else {
			// Loading.start("喝杯茶稍等一下啵");
			// Loading.start("数据加载中...");
		}
		return config;
	},
	err => {
		return Promise.reject(err);
	}
);

server.interceptors.response.use(
	response => {
		CancelApi.remove(response);
		Loading.end();
		if (
			(typeof response.data == 'string' || response.data instanceof Blob) &&
			response.status == 200
		) {
			return response;
		}
		const res = response.data;
		if (!res.status) {
			// Message.error({
			// 	message: res.message || 'Error',
			// 	type: 'error',
			// 	duration: 3000,
			// });
			if (res.code == 400) {
				return res;
			}
			if (res.code == 401) {
				// token过期
				window.location = window.location.origin;
			}
			return 
		}

		// res.message &&
		//   MessageMessage.success({
		//     message: res.message,
		//     duration: 1000,
		//   });
		return res.data;
	},
	err => {
		Loading.end();
		if (!axios.isCancel(err)) {
			Message.error({
				message: err.message,
				duration: 4000,
			});
		}
		let promise = new Promise(() => {
			setTimeout(() => {
				// promise.cancel();
			});
		});
		return promise;
	}
);

export default server;
