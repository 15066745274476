import request from "@/axios/index";

// 根据bs的token获取bi对应的token
export function tokenOrToken(data) {
    return request({
      url: '/auth',
      method: "post",
      data
    });
  }
