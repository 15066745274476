<template>
<!-- 设计图表右侧得数据修改 -->
  <div class="formBox">
    <!-- 普通柱状图 -->
    <el-form v-if="chartsTypespan == 'histogram'" :model="histogramForm" label-width="100px" class="demo-dynamic">
        <el-form-item  label="数据源" >
            <el-select style="width:180px" disabled clearable v-model="histogramForm.dataset" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.histogramForm, 'father')">
                <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="横坐标字段" >
            <el-select style="width:180px" clearable v-model="histogramForm.row" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.histogramForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="纵坐标字段" >
            <el-select style="width:180px" clearable v-model="histogramForm.col" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.histogramForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="标题" >
            <el-input size="mini" style="width:180px" v-model="histogramForm.title" @></el-input>
        </el-form-item>
        <el-form-item>
            <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
            <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search" @click="searchClick(histogramForm)">搜索</el-button>
        </el-form-item>
    </el-form>
    <!-- 多数据对比柱状图 -->
    <el-form v-if="chartsTypespan == 'charDSJZ'" :model="charDSJZForm" label-width="100px" class="demo-dynamic">
        <el-form-item label="数据源" >
            <el-select style="width:180px" disabled clearable v-model="charDSJZForm.dataset" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.charDSJZForm, 'father')">
                <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="横坐标字段" >
            <el-select style="width:180px" clearable v-model="charDSJZForm.row" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.charDSJZForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="纵坐标字段" >
            <el-select style="width:180px" clearable v-model="charDSJZForm.col" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.charDSJZForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="标题" >
            <el-input style="width:180px" size="mini" v-model="charDSJZForm.title"></el-input>
        </el-form-item>
        <el-form-item label="数据分组" >
            <el-select style="width:180px" clearable v-model="charDSJZForm.cat" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.charDSJZForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
            <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search" @click="searchClick(charDSJZForm)">搜索</el-button>
        </el-form-item>
    </el-form>
    <!-- 折线图 -->
    <el-form v-if="chartsTypespan == 'lineChart'" :model="lineChartForm" label-width="100px" class="demo-dynamic">
        <el-form-item label="数据源" >
            <el-select style="width:180px" disabled clearable v-model="lineChartForm.dataset" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.lineChartForm, 'father')">
                <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="横坐标字段" >
            <el-select style="width:180px" clearable v-model="lineChartForm.row" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.lineChartForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="纵坐标字段" >
            <el-select style="width:180px" clearable v-model="lineChartForm.col" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.lineChartForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="标题" >
            <el-input style="width:180px" size="mini" v-model="lineChartForm.title"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
            <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search" @click="searchClick(lineChartForm)">搜索</el-button>
        </el-form-item>
    </el-form>
    <!-- 折柱混合 -->
    <el-form v-if="chartsTypespan == 'lineHistogram'" :model="lineHistogramForm" label-width="100px" class="demo-dynamic">
        <el-form-item label="数据源" >
            <el-select style="width:180px" disabled clearable v-model="lineHistogramForm.dataset" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.lineHistogramForm, 'father')">
                <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="横坐标字段" >
            <el-select style="width:180px" clearable v-model="lineHistogramForm.row" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.lineHistogramForm, 'sun', chartsTypespan)">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="标题" >
            <el-input style="width:180px" size="mini" v-model="lineHistogramForm.title"></el-input>
        </el-form-item>
        <el-form-item label="柱图纵坐标" >
            <el-select style="width:180px" clearable v-model="lineHistogramForm.bars" multiple  placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.lineHistogramForm, 'sun', chartsTypespan)">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="折图纵坐标" >
            <el-select style="width:180px" clearable v-model="lineHistogramForm.lines" multiple  placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.lineHistogramForm, 'sun', chartsTypespan)">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
            <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search" @click="searchClick(lineHistogramForm)">搜索</el-button>
        </el-form-item>
    </el-form>
    <!-- 普通饼状图 -->
    <el-form v-if="chartsTypespan == 'charbB'" :model="charbBForm" label-width="100px" class="demo-dynamic">
        <el-form-item label="数据源" >
            <el-select style="width:180px" disabled clearable v-model="charbBForm.dataset" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.charbBForm, 'father')">
                <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="字段" >
            <el-select style="width:180px" clearable v-model="charbBForm.row" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.charbBForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="标题" >
            <el-input style="width:180px" size="mini" v-model="charbBForm.title"></el-input>
        </el-form-item>
        <el-form-item label="比例" >
            <el-select style="width:180px" clearable v-model="charbBForm.col" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.charbBForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
            <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search" @click="searchClick(charbBForm)">搜索</el-button>
        </el-form-item>
    </el-form>
    <!-- 联动和共享数据集 -->
    <el-form v-if="chartsTypespan == 'linkageShare'" :model="linkageShareForm" label-width="100px" class="demo-dynamic">
        <el-form-item label="数据源" >
            <el-select style="width:180px" disabled clearable v-model="linkageShareForm.dataset" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.linkageShareForm, 'father')">
                <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="横坐标字段" >
            <el-select style="width:180px" clearable v-model="linkageShareForm.row" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.linkageShareForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="纵坐标字段" >
            <el-select style="width:180px" clearable v-model="linkageShareForm.col" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.linkageShareForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="标题" >
            <el-input style="width:180px" size="mini" v-model="linkageShareForm.title"></el-input>
        </el-form-item>
        <el-form-item label="数据分组" >
            <el-select style="width:180px" clearable v-model="linkageShareForm.cat" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.linkageShareForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
            <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search" @click="searchClick(linkageShareForm)">搜索</el-button>
        </el-form-item>
    </el-form>
    <!-- 多对比图 -->
    <el-form v-if="chartsTypespan == 'contrastDSJZ'" :model="contrastDSJZForm" label-width="100px" class="demo-dynamic">
        <el-form-item label="数据源">
            <el-select style="width:180px" disabled clearable v-model="contrastDSJZForm.dataset" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.contrastDSJZForm, 'father')">
                <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="横坐标字段" >
            <el-select style="width:180px" clearable v-model="contrastDSJZForm.row" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.contrastDSJZForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="纵坐标字段" >
            <el-select style="width:180px" clearable v-model="contrastDSJZForm.col" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.contrastDSJZForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="标题" >
            <el-input style="width:180px" size="mini" v-model="contrastDSJZForm.title"></el-input>
        </el-form-item>
        <el-form-item label="日期" >
            <el-select style="width:180px" clearable v-model="contrastDSJZForm.date" placeholder="请选择" size="mini" @change="(val) => datasourceChange(val, this.contrastDSJZForm, 'sun')">
                <el-option
                    v-for="item in abscissaOption"
                    :key="item.value"
                    :label="item.showName"
                    :value="item.fname"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="比较类型">
            <el-select style="width:180px" clearable v-model="contrastDSJZForm.cmpType" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.contrastDSJZForm)">
                <!-- <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option> -->
                <el-option label="上期" value="0"></el-option>
                <el-option label="同期" value="1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单位" >
            <el-select style="width:180px" clearable v-model="contrastDSJZForm.ut" placeholder="请选择" size="mini"  @change="(val) => datasourceChange(val, this.contrastDSJZForm)">
                <!-- <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option> -->
                <el-option label="年" value="0"></el-option>
                <el-option label="季度" value="1"></el-option>
                <el-option label="月" value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
            <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search" @click="searchClick(contrastDSJZForm)">搜索</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getAbscissa } from "@/api/catConfig"
import clone from "clone"
export default {
    name: 'Y-form',
    props: {
        chartsTypeed: {
            type: String,
            default: ''
        },
        dataSourceCondition: {
            type: Object,
            default: null
        },
        dataSourceOption: {
            type:Array,
            default: []
        },
        deleteDisabled: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        chartsTypeed:{
            handler(newVal){
                this.chartsTypespan =  newVal
            },
            immediate:true,
        },
        dataSourceCondition:{
            handler(newVal){
                if (newVal.dataset != '' && newVal.dataset != null && newVal.dataset != undefined) {
                    getAbscissa({dsId: newVal.dataset}).then(res => {
                        this.abscissaOption = res
                    })
                }
                if (this.chartsTypespan == 'histogram') {
                    this.histogramForm = newVal
                } else if (this.chartsTypespan == 'charDSJZ') {
                    this.charDSJZForm = newVal
                } else if(this.chartsTypespan == 'contrastDSJZ') {
                    this.contrastDSJZForm = newVal
                }else if (this.chartsTypespan == 'lineChart') {
                    this.lineChartForm = newVal
                } else if (this.chartsTypespan == 'lineHistogram') {
                    let val = clone(newVal)

                    if(val.bar != undefined) {
                        val.bars = val.bar.map(item => {
                            return item.fname
                        })
                        delete val.bar
                    }   

                    if(val.line != undefined) {
                          val.lines = val.line.map(item => {
                            return item.fname
                        })
                        delete val.line
                    }
               
                    this.lineHistogramForm = val
                } else if (this.chartsTypespan == 'charbB') {
                    this.charbBForm = newVal
                } else if (this.chartsTypespan == 'linkageShare') {
                    this.linkageShareForm = newVal
                }
            },
            immediate:true,
        }
    },
    created () {
    },
    data () {
        return {
            histogramForm: {
                dataset: '',
                row: '',
                col: '',
            },
            charDSJZForm: {  
                dataset: '',
                row: '',
                col: '',
                cat: '',
            },
            contrastDSJZForm: {
                dataset: '',
                row: '',
                col: '',
                date: '',
                cmpType: '',
                ut: ''
            },
            lineChartForm: {
                dataset: '',
                row: '',
                col: '',
            },
            lineHistogramForm: {
                dataset: '',
                row: '',
                bars: [],
                lines: [],
            },
            charbBForm: {
                dataset: '',
                row: '',
                col: '',
            },
            linkageShareForm: {
                dataset: '',
                row: '',
                col: '',
                cat: '',
            },
            chartsTypespan: '',
            abscissaOption: []
        }
    },
    methods: {
        datasourceChange (val, forms, span, type) {
            let form = clone(forms)
            if (span == 'father') {
                for (let i in form) {
                    form[i] = ""
                }
                form.dataset = val
                getAbscissa({dsId: val}).then(res => {
                    this.abscissaOption = res
                })
                this.$emit('dataSelectChange', form)
            } else {
                
                if(type == 'lineHistogram') {
                    // 折柱图选择了多选，获取label和value
                    let barLabel = []
                    let barValue = []
                    let lineLabel = []
                    let lineValue = []
                    form.bar = []
                    form.line = []
                    //柱状多选
                    for(let i = 0; i < form.bars.length; i++) {
                        this.abscissaOption.find(item =>{
                            if(item.fname == form.bars[i]) {
                                barLabel.push(item.showName)
                                barValue.push(item.fname)
                            }
                        })
                    }
                    for(let j = 0; j < barLabel.length; j++) {
                        let obj = {
                            name:barLabel[j],
                            fname: barValue[j]
                        }
                        form?.bar.push(obj)
                    }

                    // 线图多选
                    for(let i = 0; i < form.lines.length; i++) {
                        this.abscissaOption.find(item =>{
                            if(item.fname == form.lines[i]) {
                                lineLabel.push(item.showName)
                                lineValue.push(item.fname)
                            }
                        })
                    }
                    for(let j = 0; j < lineLabel.length; j++) {
                        let obj = {
                            name:lineLabel[j],
                            fname: lineValue[j]
                        }
                        form?.line.push(obj)
                    }

                    delete form.bars
                    delete form.lines

                }
                this.$emit('dataSelectChange', form)
            }
        },
        deletelist () {
            this.$emit('deletelist')
        },
        searchClick (form) {
            this.$emit("searchClick", form)
        }
    },
    mounted () {
    }
}
</script>
<style scoped>
.formBox {
    width: 100%;
}
</style>
